import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <div className="notFound mt-5 d-flex flex-column justify-content-center align-items-center">
      <Seo title="404: Not found" />
      <h1 className="pt-5">404: Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist...</p>
      <Link to="/">Go back to the homepage</Link>
    </div>
  </Layout>

)

export default NotFoundPage
